(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:navigationBar
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="components">
   <file name="index.html">
   <neo-navigation-bar></neo-navigation-bar>
   </file>
   </example>
   *
   */
  angular
  .module('neo')
  .directive('neoNavigationBar', neoNavigationBar);

  function neoNavigationBar() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'directives/neo-navigation-bar/neo-navigation-bar-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: function ($window, $state, neoNavigationBarItems, AclService) {
        var vm = this;
        vm.name = 'navigationBar';
        vm.items = neoNavigationBarItems;

        vm.performAction = function (item, menuEvent) {
          if (angular.isDefined(item.children)) {
            menuEvent.open();
          } else {
            vm.markAsSelected(item);
            if (!_.isUndefined(item.slink)) $state.go(item.slink);
            else if (!_.isUndefined(item.url)) $window.open(item.url);
          }
        };

        vm.markAsSelected = function (itemSelected) {
          angular.forEach(vm.items, function (item) {
            item.selected = false;
          });
          itemSelected.selected = true;
        };

        vm.hasPermissionMenu = function (item) {
          if (angular.isUndefined(item.permission)) {
            return _.some(item.children, vm.hasPermission);
          } else return vm.hasPermission(item);
        };

        vm.hasPermission = function (item) {
          if (angular.isUndefined(item.permission)) {
            return true;
          }
          return AclService.can(item.permission.name, item.permission.acl);
        };
      },

      link: function (scope, element, attrs) {

      }
    };
  }
}());
